const listFormat = (lang: string, branches: string[]) => {
  const justReleases = branches.filter((branch) =>
    branch.startsWith('release/')
  );

  return new Intl.ListFormat(lang || 'pt-br', {
    style: 'long',
    type: 'conjunction',
  }).format(justReleases);
};

export default listFormat;
