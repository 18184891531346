interface VersionInfo {
  branch: string;
  version: string;
}

export function extractReleaseVersion(string: string): VersionInfo {
  const regex = /release\/(\d+\.\d+\.\d+-rc)/g;
  const match = regex.exec(string);

  if (!match) {
    return { branch: '', version: '' };
  }

  return {
    branch: match[0],
    version: match[1],
  };
}

export default function hasReleaseVersion(string: string): boolean {
  return extractReleaseVersion(string) !== null;
}
